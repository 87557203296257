import React from 'react';
import { Link } from 'gatsby';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderNodeConfig } from '../utils';
import DefaultLayout from '../layouts';
import Hero from '../components/Hero/Hero';
import SubscribeCTA from '../components/SubscribeCTA/SubscribeCTA';

const FaqTemplate = ({ pageContext }) => {
  const {
    hero,
    metaData,
    page: { question, answer },
    callToAction,
  } = pageContext;

  return (
    <DefaultLayout metaData={{ ...metaData, title: `FAQ | ${question}` }}>
      {hero && <Hero {...hero} />}
      <div className="base">
        <div className="container container--compact">
          <div className="breadcrumbs">
            <Link to="/faq">&lsaquo; Back to all FAQs</Link>
          </div>
          <h2 className="h3">{question}</h2>
          {documentToReactComponents(
            JSON.parse(answer.raw),
            renderNodeConfig(answer)
          )}
        </div>
      </div>
      {callToAction && <SubscribeCTA {...callToAction} />}
    </DefaultLayout>
  );
};

export default FaqTemplate;
